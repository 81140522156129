<template>
  <div class="feedbacks fade-up">
    <div class="dashboard-table" v-loading="isLoading">
      <div class="dashboard-table__header">
        <div
          class="dashboard-table__header-actions"
          :class="{ visible: multipleSelection.length }"
        >
          <div class="selected">{{ multipleSelection.length }} танланган</div>
          <el-button
            type="danger"
            icon="el-icon-delete"
            @click="deleteSelectedFeedbacks()"
          >
            Ўчириш
          </el-button>
        </div>
        <div class="left">
          <el-input
            placeholder="Исм буйича излаш"
            prefix-icon="el-icon-search"
            v-model="search"
          />
        </div>
        <div class="right"></div>
      </div>
      <div class="dashboard-table__body">
        <el-table
          ref="dashboardTable"
          :data="feedbacks"
          style="width: 100%"
          :default-sort="{ prop: 'created_at', order: 'descending' }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <div class="expanded">
                <p>
                  <strong>ФИШ:</strong>
                  {{
                    `${props.row.surname || "-"} ${props.row.name} ${props.row
                      .patronymic || "-"}`
                  }}
                </p>
                <p>
                  <strong>Туғилган сана:</strong>
                  {{ $date(props.row.birthdate).format("DD.MM.YYYY") }}
                </p>
                <p>
                  <strong>
                    Электрон почта:
                  </strong>
                  <a href="#" target="_blank">{{ props.row.email || "-" }}</a>
                </p>
                <p><strong>Манзил:</strong> {{ props.row.address || "-" }}</p>
                <p>
                  <strong>Мурожаат мақоми:</strong>
                  {{
                    props.row.type === "offer"
                      ? "Таклиф"
                      : props.row.type === "complaint"
                      ? "Шикоят"
                      : "Ариза"
                  }}
                </p>
                <p>
                  <strong>Мурожаат ошкоралиги:</strong>
                  {{ props.row.privacy ? "Ҳа" : "Йўқ" }}
                </p>
                <p>
                  <strong>Тадбиркор:</strong>
                  {{ props.row.entrepreneur ? "Ҳа" : "Йўқ" }}
                </p>
                <p>
                  <strong>Файл:</strong>
                  <a
                    v-if="props.row.file"
                    :href="$baseUrl + props.row.file.url"
                    download
                    target="_blank"
                  >
                    <span>Юклаш</span>
                    <i class="el-icon-download icon"></i>
                  </a>
                  <span v-else>-</span>
                </p>
                <p><strong>Хабар матни:</strong> {{ props.row.message }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column type="selection" width="55" />
          <el-table-column
            prop="created_at"
            label="Мурожат вақти"
            width="160"
            sortable
          >
            <template slot-scope="scope">
              {{ $date(scope.row.created_at).format("HH:mm | DD.MM.YYYY") }}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="Исм">
            <template slot-scope="scope">
              {{ scope.row.name }}
            </template>
          </el-table-column>
          <el-table-column label="Амаллар" width="120">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click.stop="deleteFeedback(scope.row)"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="dashboard-table__footer">
        <el-pagination
          :disabled="isLoading"
          background
          layout="prev, pager, next"
          :page-size="perPage"
          :total="totalCount"
          :current-page.sync="currentPage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  data: () => ({
    isLoading: false,
    search: "",
    perPage: 6,
    currentPage: 1,
    multipleSelection: []
  }),
  computed: {
    feedbacks() {
      return this.$store.state.feedbackModule.feedbacks;
    },
    totalCount() {
      return this.$store.state.feedbackModule.feedbacksCount;
    }
  },
  created() {
    this.fetchFeedbacks();
  },
  watch: {
    currentPage() {
      if (this.isLoading) return;

      this.fetchFeedbacks();
    },
    search: _.debounce(function() {
      this.currentPage = 1;
      this.fetchFeedbacks();
    }, 500)
  },
  methods: {
    fetchFeedbacks() {
      if (this.isLoading) return;

      this.isLoading = true;
      const params = {
        _start: this.currentPage * this.perPage - this.perPage,
        _limit: this.perPage,
        "_where[_or][0][name_contains]": this.search
      };
      Promise.all([
        this.$store.dispatch("fetchFeedbacks", params),
        this.$store.dispatch("fetchFeedbacksCount", params)
      ]).finally(() => {
        this.isLoading = false;
      });
    },
    deleteSelectedFeedbacks() {
      this.$confirm("Ўчириш амалини тасдиқлайсизми?", "Диққат!", {
        confirmButtonText: "Ха",
        cancelButtonText: "Бекор қилиш",
        type: "warning"
      })
        .then(() => {
          this.isLoading = true;
          Promise.all(
            this.multipleSelection.map(r => {
              this.$store.dispatch("deleteFeedback", r.id);
            })
          )
            .then(() => {
              if (this.feedbacks.length === 0) {
                this.isLoading = false;
                this.fetchFeedbacks();
              }
              this.$message({
                type: "success",
                message: "Ўчирилди"
              });
            })
            .finally(() => (this.isLoading = false));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Бекор қилинди"
          });
        });
    },
    deleteFeedback(row) {
      this.$confirm("Ўчириш амалини тасдиқлайсизми?", "Диққат!", {
        confirmButtonText: "Ха",
        cancelButtonText: "Бекор қилиш",
        type: "warning"
      })
        .then(() => {
          this.isLoading = true;
          this.$store
            .dispatch("deleteFeedback", row.id)
            .then(() => {
              if (this.feedbacks.length === 0) {
                this.isLoading = false;
                this.fetchFeedbacks();
              }
              this.$message({
                type: "success",
                message: "Ўчирилди"
              });
            })
            .finally(() => (this.isLoading = false));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Бекор қилинди"
          });
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.feedbacks {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
</style>
